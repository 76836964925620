  // const xlMax = ;
//   const xlMin = isOpen ? 2303 : 2135;
  // const lgMax = ;
//   const lgMin = isOpen ? 1968 : 1800;
  // const mdMax = ;
  const mdMin = 1143;
  const smMax = mdMin - 1;
  const smMin = 887;
  const xsMax = smMin - 1;
  const xsMin = 512;

export {xsMin, xsMax, smMin, smMax, mdMin};